import React from "react";

export const UL = ({ title, children }: any) => {
  return (
    <div className="UL">
      <div className="UL_title">{title}</div>
      <ul>{children}</ul>
    </div>
  );
};
