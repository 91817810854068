import React, { useRef } from "react";
import { Carousel, Row, Col } from "antd";
import { TextSection } from "../common/TextSection";
import { UL } from "../common/UL";
// @ts-expect-error
import swiss from "../../images/brochureSwiss.webp";
// @ts-expect-error
import swissPng from "../../images/brochureSwiss.png";
// @ts-expect-error
import sg from "../../images/brochureSg.webp";
// @ts-expect-error
import sgPng from "../../images/brochureSg.png";
// @ts-expect-error
import hg from "../../images/brochureHg.webp";
// @ts-expect-error
import hgPng from "../../images/brochureHg.png";

import RightArrow from "../icons/RightArrow";
import LeftArrow from "../icons/LeftArrow";
import { StaticImage } from "gatsby-plugin-image";

export const Geographical = () => {
  const carroossa = useRef();
  return (
    <div>
      <section>
        <TextSection title="GEOGRAPHICAL DIVERSIFICATION AND OFFSHORE STORAGE">
          <p className="text-section__info">
            If history is any guide, gold has been the object of confiscation
            because it represents an obvious concentration of wealth. Such
            confiscation can take the form of theft if done by private
            individuals, or public policy if done by governments. Governments
            can unfortunately reach out to the safest private storage facilities
            and insurance does not cover government confiscation.
            <br />
            <br />
            <strong>
              When choosing a jurisdiction in which to store a large amount of
              wealth, it is crucial that:
            </strong>
            <UL>
              <li>
                It is governed under the rule of law and has an efficient legal
                system.
              </li>
              <li>It has a low motivation to expropriate assets</li>
              <li>It has balanced finances.</li>
            </UL>
            <br />
            <br />
            Unfortunately, these criteria rule out most of the Western world as
            viable options. Investors are very concerned about the Western
            banking system and the risks of bail-ins, deposit confiscations and
            capital controls.
            <br />
            <br />
            As a result, the world’s growing middle classes and the wealthy are
            moving their gold away from increasing financial repression in the
            Western world to the Asian capitals of Hong Kong and Asia’s emerging
            precious metals trade hub, Singapore. Switzerland remains a favorite
            destination for many western and international investors, both
            retail and institutional. Let us review the jurisdictions that are
            considered the safest.
          </p>
        </TextSection>
      </section>
      <section className="geographic_section2">
        <Row gutter={10} className="carousel_slick_btns_cont">
          <Col>
            <div
              className="direction_btn"
              onClick={() => {
                // @ts-expect-error
                carroossa.current.prev();
              }}
            >
              <LeftArrow color="#d7c596" />
            </div>
          </Col>
          <Col>
            <div
              className="direction_btn"
              onClick={() => {
                // @ts-expect-error
                carroossa.current.next();
              }}
            >
              <RightArrow color="#d7c596" />
            </div>
          </Col>
        </Row>
        <Carousel ref={carroossa}>
          <div className="geographic_section2_content">
            <div className="geographic_section2_img">
              <StaticImage
                src="../../images/brochureSwiss.png"
                style={{ height: "100%" }}
                alt="Swiss city night view"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
            </div>
            <div className="geographic_section2_main">
              <div className="geographic_section2_title">
                SWITZERLAND
                <div className="line" />
              </div>
              <div className="geographic_section2_karta">
                Switzerland is in our opinion one of the safest countries for
                gold storage and is said to concentrate the greatest amount of
                art and valuables of any storage location in the world. Since
                Switzerland gained independence in 1648, it has remained neutral
                and has not been engaged in any foreign conflicts. The country
                has a long tradition of safekeeping wealth.
                <br />
                <br />
                However, the country is locked by European countries and
                increasingly submitting to the regulatory pressure by the
                European Union. Switzerland is still a safe option and certainly
                the most viable historically, but investors should stay informed
                of the potential future changes that might affect their holdings
                held in Switzerland.
              </div>
            </div>
          </div>
          <div className="geographic_section2_content">
            <div className="geographic_section2_img">
              <StaticImage
                src="../../images/brochureSg.png"
                style={{ height: "100%" }}
                alt="Singapore city night view"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
            </div>
            <div className="geographic_section2_main">
              <div className="geographic_section2_title">
                SINGAPORE
                <div className="line" />
              </div>
              <div className="geographic_section2_karta">
                As a financial and wealth management hub, Singapore is well
                positioned to accommodate the growing demand for physical
                precious metals as it combines all the necessary ingredients:
                Political and economic stability:
                <br />
                <br /> Singapore is a young nation but has seen impressive
                economic growth due to its efficient legal system, absence of
                corruption, and a business friendly government. Favorable tax
                treatment of precious metals: Singapore abolished VAT/GST on
                investment grade precious metals in 2012. A State-of-the-Art
                Freeport: It is located next to Singapore’s Changi Airport and
                is operated with the support of the Economic Development Board
                of Singapore, the Singapore Customs, the Singapore Police and
                the Civil Aviation Authority of Singapore. The four-storey
                building has 25,000 square metres of space and is operated as a
                maximum security, highly confidential, duty-free zone facility.
              </div>
            </div>
          </div>
          <div className="geographic_section2_content">
            <div className="geographic_section2_img">
              <StaticImage
                src="../../images/brochureHg.png"
                style={{ height: "100%" }}
                alt="Hong Kong city night view"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
            </div>
            <div className="geographic_section2_main">
              <div className="geographic_section2_title">
                HONG KONG
                <div className="line" />
              </div>
              <div className="geographic_section2_karta">
                Hong Kong is an important storage and distribution hub for
                international bullion banks. The facility of our vault operator
                is located on the ground floor of a building within the
                international airport compound, and has a capacity for 1,000
                tons. The building security consists of 24-hour monitoring
                bolstered with armed auxiliary police officers, an invisible
                laser sensor network surrounding the facility, and an infra-red
                motion detection security system.
                <br />
                <br /> Hong Kong is one of the most stable, economically free
                regions of the world, maintaining a business friendly, low-tax
                environment with a robust financial and legal infrastructure in
                place. Hong Kong is also a logistics hub for bullion that
                contains the whole ecosystem required for trading and storing
                metals, and further, is home to two refineries accredited by the
                LBMA . It is the import gateway for precious metals into China,
                which have soared in recent years. Goods can move freely in and
                out of the city with minimal government interference.
              </div>
            </div>
          </div>
        </Carousel>
      </section>
    </div>
  );
};
