import React, { useEffect, useState } from "react";
import { Input, Form, message } from "antd";
import { TextSection } from "../common/TextSection";
import RightArrow from "../icons/RightArrow";
import { sendBrochure } from "../../utils/api";

export const Introduction = () => {
  const [form] = Form.useForm();
  const [bottom, setBottom] = useState(100);
  useEffect(() => {
    const onResize = () => {
      const f = document.getElementsByClassName("footer_bar")[0];
      setBottom(f.clientHeight);
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div>
      <TextSection title="INTRODUCTION">
        <p
          style={{
            lineHeight: "24px",
          }}
          className="text-section__info"
        >
          The liberty-minded individual is turning to precious metals as a safe
          haven in these economically troubled times. He knows that gold has
          acted as a stable store of value for thousands of years while the
          value of world currencies has steadily declined. He understands the
          need to own tangible and liquid assets, even more so in an environment
          of ever-expanding fiat money and government debt. He has decided to
          partially opt-out of the current financial system by directly owning
          tangible wealth. This report will refer to this liberty-minded
          individual as the Wise Investor, who has decided to take control of
          his wealth.
          <br />
          <br />
          On top of that, major global institutions have been caught
          perpetrating frauds and manipulating all markets. In these days of
          unprecedented financial experiment, the Wise Investor has made up his
          mind to diversify part of his portfolio with physical precious metals.
          <br />
          <br />
          This short guide aims to lay out the options available to the
          liberty-minded individual who wishes to buy and hold precious metals
          for long-term wealth protection.
        </p>
      </TextSection>
      <div className="download_brochure" style={{ bottom }}>
        <Form
          form={form}
          size="large"
          className="download_brochure_form header-home"
          onFinish={() => {
            const { email } = form.getFieldsValue();
            sendBrochure({ email }).then(async r => {
              if (r.ok) {
                message.success("Brochure has been succesfully to you email");
              }
            });
          }}
        >
          <div className="download_brochure_title">Download Brochure:</div>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please select email",
              },
            ]}
          >
            <Input
              placeholder="Your email"
              className="download_brochure_input"
            />
          </Form.Item>
          <div
            onClick={() => {
              form.submit();
            }}
            className="btn hero__content-btn contact-us-btn"
          >
            <div>Download PDF</div> <RightArrow />
          </div>
        </Form>
      </div>
    </div>
  );
};
