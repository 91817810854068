import React, { useState } from "react";
import { graphql } from "gatsby";
import { Col, Row, Tabs } from "antd";

import "../styles/broshure.scss";

import { SEO, Layout, Footer } from "../components";
import { HomeHeader } from "../components/Headers/HomeHeader";
import { Conclusion } from "../components/broshure/Conclusion";
import { Appendix } from "../components/broshure/Appendix";
import { Disclaimer } from "../components/broshure/Disclaimer";
import { Introduction } from "../components/broshure/Introduction";
import { TheCase } from "../components/broshure/TheCase";
import { FiveStep } from "../components/broshure/FiveSteps";
import { Physical } from "../components/broshure/Physical";
import { Geographical } from "../components/broshure/Geographical";
import { Solutions } from "../components/broshure/Solutions";

type PageProps = {
  data: {
    contentfulPage: {
      title: string,
      seoDescription: string,
      socialShareThumbnail: {
        file: {
          url: string
        }
      }
    }
  }
}

const GoldGuide = ({ data }: PageProps) => {
  const [activeId, setId] = useState("introduction");
  const { title, seoDescription, socialShareThumbnail } = data.contentfulPage;

  return (
    <Layout
      header={<HomeHeader style={{ marginBottom: 0 }} />}
      footer={<Footer />}
    >
      <SEO
        title={title}
        desc={seoDescription}
        image={socialShareThumbnail.file.url}
      />
      <div className="pageTitle header-home">
        <h1 className="pageTitle_name text-uppercase">Gold Guide</h1>
        <Row className="pageTitle_elm2" gutter={5}>
          <Col>HOME</Col>
          <Col>
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.49792 -0.000114648L0 3.4978L3.53553 7.03334L7.03345 3.53542L3.49792 -0.000114648Z"
                fill="#BDA06D"
              />
            </svg>
          </Col>
          <Col>
            <span className="pageTitle_e2">GOLD GUIDE</span>
          </Col>
        </Row>
      </div>
      <div className="broshure_mainContainer">
        <Tabs
          defaultActiveKey="introduction"
          activeKey={activeId}
          onChange={setId}
          renderTabBar={(p, C) => (
            <div className="cr cl">
              <C {...p} />
            </div>
          )}
        >
          <Tabs.TabPane tab="INTRODUCTION" key="introduction">
            <Introduction />
          </Tabs.TabPane>
          <Tabs.TabPane tab="THE CASE" key="the_case">
            <TheCase />
          </Tabs.TabPane>
          <Tabs.TabPane tab="FIVE-STEP" key="five_step">
            <FiveStep />
          </Tabs.TabPane>
          <Tabs.TabPane tab="PHYSICAL" key="physical">
            <Physical />
          </Tabs.TabPane>
          <Tabs.TabPane tab="GEOGRAPHICAL" key="geographical">
            <Geographical />
          </Tabs.TabPane>
          <Tabs.TabPane tab="SOLUTIONS" key="solutions">
            <Solutions />
          </Tabs.TabPane>
          <Tabs.TabPane tab="CONCLUSION" key="conclusion">
            <Conclusion />
          </Tabs.TabPane>
          <Tabs.TabPane tab="APPENDIX" key="appendix">
            <Appendix />
          </Tabs.TabPane>
          <Tabs.TabPane tab="DISCLAIMER" key="disclaimer">
            <Disclaimer />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default GoldGuide;

export const query = graphql`
  query GoldGuidePageQuery {
    contentfulPage(slug: {eq: "gold-guide"}) {
      title
      seoDescription
      socialShareThumbnail {
        file {
          url
        }
      }
    }
  }
`