import React from "react";
import { Row, Col } from "antd";
import { TextSection } from "../common/TextSection";
import { StaticImage } from "gatsby-plugin-image";

export const Appendix = () => (
  <div>
    <TextSection
      title="A WORD ON THE LONDON BULLION MARKET … AND WHY THE WISE INVESTOR SHOULD STAY AWAY FROM IT
"
    >
      <div className="appendix__text">
        Anyone even remotely interested in gold or silver often encounters the
        name of the London Bullion Market Association (LBMA). The LBMA is an
        international trade association that represents the wholesale market for
        gold and silver bullion, and it has two main roles:
        <br />
        <br />
        <ul className="conclusion__ul">
          <li>
            1. It governs the London bullion market, where most of the world’s
            physical gold trades. The London market is the focus of the over the
            counter (OTC) market for physical bullion, with a client base that
            includes producers, refiners, fabricators, and the majority of the
            world’s central banks.
          </li>
          <li>
            2. The association sets the industry standards in terms of
            manufacturing, assaying, and vaulting of the gold in the London
            market. The LBMA publishes a list of accredited refiners (called the
            Good Delivery List) whose standards of production and assaying meet
            the requirements set out in the LBMA’s rules. Bars produced by such
            refiners and conforming to size and purity standards are called Good
            Delivery bars. Only bullion conforming to these standards is
            acceptable in settlement against transactions conducted between
            participants in the bullion market.
          </li>
        </ul>
      </div>
    </TextSection>

    <Row
      gutter={38}
      className="appendix__row"
      style={{ backgroundColor: "#F1F2EA", paddingTop: 70 }}
    >
      <Col xs={24} md={10} lg={10} xl={10}>
        <div className="side_card-left cl">
          2013 SAW AN AVERAGE OF 219.5 MILLION OUNCES OF GOLD BEING TRADED EACH
          DAY IN THE LONDON MARKET. THIS EQUATES TO 6,826 METRIC TONS OF GOLD
          CHANGING HANDS DAILY IN 2013, WITH A VALUE OF US$310 BILLION.
        </div>
      </Col>
      <Col xs={24} md={14} lg={14} xl={14}>
        <div className="cr appendix__cr">
          Unlike other commodity exchanges, the London Bullion Market does not
          operate as a commodities exchange with a clearing house as a central
          counterparty, but instead operates on an Over-the-Counter (OTC) or
          principal-to-principal basis. What this means is that buyers and
          sellers choose each other, and do not necessarily find the best price
          on an exchange floor purely through price competition among different
          players. It is important to understand the structure of this market to
          see the concentration and control that some of the largest banks
          maintain over it.
          <br />
          <br />
          <ul className="conclusion__ul">
            <li>
              There are eleven market-making firms in the London Bullion Market
              that provide two-way price quotations during London bullion
              trading hours. These members are Barclays Bank, Goldman Sachs,
              Credit Suisse, Deutsche Bank, HSBC, JP Morgan, Merrill Lynch,
              Mitsui, Société Générale, UBS AG and the Bank of Novia Scotia
              (Scotia Mocatta).
            </li>
            <li>
              There are six clearing members of the London Bullion Market
              (Barclays, Deutsche, HSBC, JPMorgan, Scotiabank and UBS) that
              together own and operate London Precious Metal Clearing Limited
              (LPMCL). Note that the two biggest players from a clearing
              perspective are JP Morgan and HSBC.
            </li>
            <li>
              There are five banks that are market-making members and
              responsible for setting the price twice daily. They are
              Scotiabank, Barclays Bank Plc, Deutsche Bank AG, HSBC Bank USA and
              Société Générale.
            </li>
            <li>
              The clearing members maintain secure vaulting facilities, but only
              three of them have their own vaults (Barclays, HSBC, and JP
              Morgan). The other clearing members use the services of three
              security carriers, also LBMA members (Brinks, Malca Amit and
              ViaMat) and of the Bank of England.
            </li>
            <li>
              In addition to the clearers, there is the Bank of England, which
              clears the net gold positions among the clearers each day by
              making book entry transfers into unallocated accounts in respect
              of each clearer’s trades. The balances of the clearers at the Bank
              of England represent their entitlement to the bank’s bullion, and
              within the rules of the LBMA the clearers can treat it as if it
              were physical reserve (even though nobody outside the bank ever
              gets to look at the actual metal itself).
            </li>
          </ul>
          The London market operates in absolute confidentiality due to the fact
          that trading between LBMA market makers and their clients is on an OTC
          basis. Moreover, since the passage of the Financial Services and
          Markets Act 2000, spot and forward trading in bullion in the UK have
          not been regulated activities. This results in the London bullion
          market being one of the most opaque markets … despite being one of the
          largest markets in the world. As per the LBMA website, last year
          clearing1 statistics were nothing short of astounding: “In 2013 the
          daily average amount of gold cleared through London was 21.95 million
          ounces with an average daily value of $31 billion.”2 It also later
          clarifies that “these are net figures; the gross turnover of gold and
          silver is expected to be considerably higher).” It’s constantly a
          source of amazement to the gold world that these LBMA average daily
          clearing statistics are so large. For example, 21.95 million troy
          ounces is equivalent to 668 metric tons of gold being cleared each day
          through the London Market. As admitted on their website, the gross
          turnover is “considerably higher.” In 2011, the LBMA surveyed trading
          activity amongst its trading members in an attempt to gauge the
          liquidity of the market.3 Despite the fact that only 36 of the 56 full
          members involved in trading gold submitted returns, the report states:
          “there is an approximately ten to one ratio between the turnover
          figures and the clearing statistics.”
        </div>
      </Col>
    </Row>

    <Row
      gutter={38}
      className="appendix__row"
      style={{ backgroundColor: "rgba(241,242,234,0.5)" }}
    >
      <Col xs={24} md={10} lg={10} xl={10}>
        <div className="side_card-left cl">
          SO THIS MEANS THAT 2013 SAW AN AVERAGE OF 219.5 MILLION OUNCES OF GOLD
          BEING TRADED EACH DAY IN THE LONDON MARKET. THIS EQUATES TO 6,826
          METRIC TONS OF GOLD CHANGING HANDS DAILY IN 2013, WITH A VALUE OF
          US$310 BILLION (AND THIS IS A LOW ESTIMATE OF ACTUAL TRADING VOLUME
          PER THE LBMA’S OWN ADMISSION).
        </div>
      </Col>
      <Col xs={24} md={14} lg={14} xl={14}>
        <div className="cr appendix__cr">
          Bearing in mind that global gold mine production is circa 2,800 tons
          per annum (or 7.7 tons per day), we can see that the volume of gold
          traded in London every day is 890 times the annual output of the
          world’s gold mines – and 4% of the quantity of gold that ever has been
          mined.
          <br />
          The LBMA website further states that: “In total there is approximately
          9,000 tons of gold held in London vaults, of which about two thirds is
          stored in the Bank of England.”4 Well, it appears that those 9,000
          tons must change hands every day and half or so, given a trading
          volume of 6,826 tons per day … unless, of course, most of the activity
          in the London Bullion Market has little to do with physical gold.
          <br /> In April 2013, the LBMA with HM Revenue and Customs (HMRC)
          drafted a document clarifying the treatment of VAT for the London
          precious metals markets.5 The consideration of whether physical goods
          or services are sold is central to a discussion on VAT. The report
          revealed that 95% of the trades in the London precious metals market
          are unallocated metal: “95% of transactions are in unallocated metal:
          therefore, because they are treated as services, the location of the
          underlying metal is not relevant.”
          <br />
          <br />
          So let’s recap what we now know about the London Bullion Market:
          <br />
          <br />
          <ul className="conclusion__ul">
            <li>
              1. It is essential to purchase physical bullion that is Good
              Delivery or has been manufactured by a refinery accredited by the
              LBMA because it is a sign of quality recognized globally and it
              facilitates future liquidity of the metal.
            </li>
            <li>
              2. The London Bullion Market is at least 95% a paper market,
              consisting of gold denominated debts issued by over-leveraged
              bullion banks. Assuming that the 9,000 tons held in London vaults
              represents 5% of the total London Bullion Market,6 it means that
              175,000 tons of paper gold with no backing whatsoever are
              currently being held by (mostly) unsuspecting investors. And this
              is, of course, a low estimate because it assumes that those 9,000
              tons have not been re-hypothecated several times. One hundred
              seventy-five thousand tons is more than all the gold ever mined
              and represents a value of approximately US$7.3 trillion.
            </li>
            <li>
              3. Given these alarming figures, a run on the London Bullion
              Market is likely to happen at some point. Investors desiring to
              hold precious metals for the long term as a crisis protection
              should stay away from the London Market and from any product
              involving its financial participants.
            </li>
          </ul>
        </div>
      </Col>
    </Row>
    <div style={{ backgroundColor: "#F1F2EA" }}>
      <TextSection title="MAJOR BRANDS AND BAR SIZES">
        <div className="appendix__text">
          The dimensions of the bars are indicated in millimeters.
        </div>
      </TextSection>
      <Row className="cr cl appendix__crl">
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Pamp 1 kilo</div>
          <div className="appendix__barsdetails">
            Cast Bar (116 x 51 x 8.67)
          </div>
          <StaticImage
            src="../../images/pampKilo.svg"
            alt="Pamp 1 kilo Cast Bar (116 x 51 x 8.67)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Heraeus 500g</div>
          <div className="appendix__barsdetails">
            Cast Bar (91.0 x 41 x 7.6)
          </div>
          <StaticImage
            src="../../images/heraeus.svg"
            alt="Heraeus 500g Cast Bar (91.0 x 41 x 7.6)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Perth Mint 10oz</div>
          <div className="appendix__barsdetails">Cast Bar (47 x 21 x 15)</div>
          <StaticImage
            src="../../images/perthMint.svg"
            alt="Perth Mint 10oz Cast Bar (47 x 21 x 15)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Argor Heraeus 250g</div>
          <div className="appendix__barsdetails">Cast Bar (50 x 30.5 x 9)</div>
          <StaticImage
            src="../../images/argorHeraeus.svg"
            alt="Argor Heraeus 250g Cast Bar (50 x 30.5 x 9)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Metalor 100g </div>
          <div className="appendix__barsdetails">
            Cast Bar (116 x 51 x 8.67){" "}
          </div>
          <StaticImage
            src="../../images/metalor.svg"
            alt="Metalor 100g Cast Bar (116 x 51 x 8.67)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
          <div className="appendix__barsName">Tanaka 50g </div>
          <div className="appendix__barsdetails">
            Cast Bar (116 x 51 x 8.67)
          </div>
          <StaticImage
            src="../../images/tanaka.svg"
            alt="Tanaka 50g Cast Bar (116 x 51 x 8.67)"
            placeholder="blurred"
            layout="fixed"
            formats={["auto", "webp"]}
          />
        </Col>
      </Row>
    </div>
  </div>
);
