import React from "react";
import { TextSection } from "../common/TextSection";

import { StaticImage } from "gatsby-plugin-image";

export const Conclusion = () => (
  <div>
    <TextSection title="CONCLUSION">
      <div
        style={{
          fontSize: "16px",
          fontStyle: "normal",
          lineHeight: "24px",
        }}
        className="text-section__info"
      >
        Investors who own only a limited quantity of metal simply can store it
        at home hidden and in secret. For larger quantities, the services of
        private storage facilities should be sought. Wise precious metals
        investors only should consider an allocated and segregated storage
        solution that presents the following characteristics:
        <br />
        <br />
        <span
          style={{ lineHeight: "30px", fontSize: "18px", fontWeight: "bold" }}
        >
          When choosing a jurisdiction in which to store a large amount of
          wealth, it is crucial that:
        </span>
        <br />
        <br />
        <ul className="conclusion__ul">
          <li>
            1. Established in a stable jurisdiction known for efficient
            commercial law and property rights enforcement.
          </li>
          <li>
            2. Offered by a reputable provider, preferably being part of a
            reputable group of companies and with the directors clearly
            identified and accountable.
          </li>
          <li>
            3. Full insurance coverage up to the replacement value of the metal,
            at no additional cost.
          </li>
          <li>
            4. Documentation clearly evidencing the title in the property by the
            investor. This is usually accomplished with a sale invoice by the
            company. In the case of storage being provided by the company, this
            is sometimes complemented by a warehouse receipt and a certificate
            of ownership. These two documents have little legal value, but
            reinforce the fact that the ownership lies with the investor.
          </li>
          <li>
            5. A storage agreement that clearly limits what the supplier can do
            with the investor’s metal and outlines his role as a mere service
            provider.
          </li>
          <li>
            6. Documentation and statements setting forth the exact weight,
            manufacturer, fineness, and bar number of your property. Failure to
            do this by the provider means that you are involved in an
            unallocated storage scheme.
          </li>
          <li>7. Regular audits by independent third-party auditors. </li>
          <li>
            8. Competitive storage and administrative fees. Such fees should
            never exceed 1% of the value of the metal per annum and should be
            closer to half a percent (including insurance).
          </li>
          <li>
            9. Ability to sell your holdings back to the supplier in a
            convenient and fast way.
          </li>
          <li>
            10. Ability to pick up your bullion at the storage facility with
            minimum notice to the provider (48 hours maximum).
          </li>
        </ul>
        <span
          style={{
            lineHeight: "30px",
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "12px",
          }}
        >
          Investors can structure the geographical distribution of their
          precious metals assets in the following way:
        </span>
        <br />
        <br />
        <ul className="conclusion__ul">
          <li>
            1. Small quantities of bullion can be held at home so that they are
            available in case of emergency.
          </li>
          <li>
            2. Larger quantities can be held in private storage in the
            investor’s country of residence so that it can be reached within a
            few hours’ drive should the emergency persist.
          </li>
          <li>
            3. The rest of the allocation should be held overseas in allocated,
            segregated, and insured storage located in stable jurisdictions.
          </li>
        </ul>
        <StaticImage
          src="../../images/graph-4.png"
          imgStyle={{
            mixBlendMode: "darken", margin: "50px 0",
            width: "100%",
            height: "auto"
          }}
          alt="Storage Y to Percentage of Precious metals X Graph"
          placeholder="blurred"
          layout="fullWidth"
          formats={["auto", "webp"]}
        />
      </div>
    </TextSection>
  </div>
);
