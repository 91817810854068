import React, { useState, useMemo } from "react";
import { Col, Row, Radio, Select } from "antd";
import { TextSection } from "../common/TextSection";
import { useWindowSize } from "../../utils/hooks";

const SubSlides = () => {
  const [a, sa] = useState(0);
  const ws = useWindowSize();
  const tabs = useMemo(() => {
    return [
      <div key="1">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>HOME STORAGE</h1>
            <div>
              The investor can visit a gold shop armed with his calculator and a
              well-stocked wallet and go home discretely making sure that nobody
              followed him. This works perfectly for small purchases and can
              constitute a smart regular saving routine. However, not many
              people would feel comfortable doing so for larger amounts, such as
              kilo bars worth US$42,000 each.
              <br />
              <br />
              Let’s assume that our adventurous investor made it home safely
              with a few kilo gold bars. He can choose to store his bars in a
              safe located in his home but he should be aware that this brings
              an additional risk to the safety of his family should this fact be
              known by other people. Home insurance also is not much of an
              option in most countries and if it is, its cost would be
              prohibitive.
              <br />
              <br />
              Burying the gold secretly in the garden is a great option for
              those who have a garden but the secrecy is difficult to maintain
              for the investor who wants to adequately plan for the transfer of
              his wealth to his heirs in case of an unfortunate event. Countless
              quantities of bullion are likely still resting below the ground
              since their owner passed away.
            </div>
          </div>
        </Col>
      </div>,

      <div key="2">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>SAFE DEPOSIT BOXES</h1>
            <div>
              Safe deposit boxes managed by professional institutions are the
              best option for the storage of a few gold bars. However, there are
              limitations to the solutions provided:
              <br />
              <br />
              The boxes are usually small and have a maximum weight capacity
              which is quickly reached with bullion. The storage of silver is
              also out of the question, given the bulky nature of the metal.
              Most providers of such boxes are banks. While they do not have
              access to the content (we hope), they are involved in granting
              access to the box. This means that the investor wouldn’t be able
              to access his box in the case of a bank holiday, which is when he
              needs it the most. Another detail is that he would need (in most
              cases) to open a bank account with the bank, which might involve
              some level of government disclosure and indicate that he has
              assets in that bank. Most bank safe deposit boxes do not offer the
              option to insure their contents, which might raise the question of
              what happens if the contents of the box disappear. And cases of
              items missing from deposit boxes are legion.
              <br />
              Another major problem with any safe deposit box is the loss of
              integrity of the gold that is stored in it. Bars that are kept at
              all times in vaults recognized by the gold bullion dealing
              community remain within what is called the “chain of integrity.”
              Within that community, a record is made of every movement of a bar
              between recognized vaults and such bar can be sold easily and
              quickly without the need to assay it. There are non-financial
              institutions that provide safe deposit boxes service with
              insurance. Being non-financial firms, they wouldn’t be impacted by
              a bank holiday and would not have the same disclosure regulations
              as banks. These firms are a far better option.
            </div>
          </div>
        </Col>
      </div>,

      <div key="3">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr ">
            <div>
              <h1>ALLOCATED METAL ACCOUNTS</h1>
              <div>
                Once the Wise Investor’s gold holding outgrows his safe deposit
                box, the next step is to consider the most secure storage of
                all: a private, precious-metal vaulting facility that takes care
                of the storage and insurance. The client retains total ownership
                of his metal and enters into a service agreement with the
                provider who acts as a bailee and cannot make any use of the
                assets whatsoever.
                <br />
                <br />
                Such a solution is best for the storage of large amounts of
                bullion that do not fit in a deposit box or for the overseas
                storage of metal as part of a geographical diversification
                strategy (because it doesn’t require you to be there personally
                to move the bars). The greatest advantage of such a solution is
                the increased liquidity of the metal held, because it has
                remained in the custody of a trusted vault operator who can
                provide a guarantee to a potential buyer that the bars never
                left its custody and remained in the chain of integrity. Such a
                provider can, in some cases, assist in the purchase or sale of
                metal and arrange the transportation of the goods to other
                locations upon instructions by the client. Such arrangement, of
                course, necessitates a fair amount of trust in the provider, who
                should have an impeccable reputation.
                <br />
                <br />
                With an allocated gold account, the client is the direct owner
                of the metal, and the metal does not appear on the balance sheet
                of the provider. The provider is merely acting as custodian and
                the investor pays a storage fee that usually includes insurance.
                The storage fee varies with the volume stored and the storage
                location.
                <br />
                <br />
                There are two types of allocated accounts: pooled allocated
                accounts and segregated allocated accounts.
              </div>
            </div>
          </div>
        </Col>
      </div>,

      <div key="4">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>POOLED ALLOCATED ACCOUNTS</h1>
            <div>
              Such accounts allow investors to purchase a fractional interest in
              a pool of gold that most often consists of Good Delivery 400 oz
              bars. The gold must be purchased through the provider, which
              doesn’t allow for outside bullion to be deposited in its storage.
              Those accounts are entirely backed by physical gold, but the
              specific bars are not allocated to investors, who hold a
              collective claim on the metal, along with all other investors.
              These suppliers offer fully insured and unencumbered ownership in
              the pool of metal that is stored with non-bank custodians at very
              attractive rates.
              <br />
              <br />
              Because the gold pool is not segregated on a client basis, several
              issues arise:
              <br />
              <br />
              Clients cannot inspect their holdings.
              <br />
              Withdrawal of large 400 oz bars is the only affordable option.
              Withdrawal of smaller bars involves swapping large bars with
              smaller bars with a refiner and involves hefty additional charges.
              While these pooled accounts represent an easy way to own bullion,
              owning a fraction of a large bar will not be of much help should
              many investors ask for physical delivery at the same time.
              Swapping large bars with smaller ones or getting them refined into
              small bars might not be possible in a scenario of economic panic.
              Should the refineries be operating at full capacity during that
              period, the bullion would be locked for a while due to its
              collective ownership. Such accounts offer the investor the option
              to “register” his bars and have them physically segregated but
              often at a prohibitive cost.
            </div>
          </div>
        </Col>
      </div>,

      <div key="5">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr ">
            <div>
              <h1>SEGREGATED ALLOCATED ACCOUNTS</h1>
              <div>
                With such an account the investor owns metal that is identified
                by bar type, unique serial number, gross weight, the assay or
                fineness of each bar, and its fine weight. Credits or debits to
                the holding are linked to the physical movements of bars, to or
                from the client’s physical holding. The investor outright owns
                specific bars that have been fabricated and are sitting insured
                in a vault segregated from holdings of other clients.
                <br />
                <br />
                There are several types of providers of such accounts:
                <br />
                <br />
                1 – Bullion banks provide allocated accounts and charge a yearly
                storage fee ranging between 0.1% and 0.6% of the value of the
                metal, depending on the size of the investment and the
                relationship with the client. A feature of the London Bullion
                Market is that the clearing members act as sub-custodian for
                each other. This means that in the case of bullion held in an
                allocated account with a bullion bank, the property will exist
                but its location will be a bit of a secret. One clearer’s
                allocated collection (comprising individually identified 400 oz
                bullion bars) is most likely distributed across multiple vaults.
                <br />
                <br />
                2 – Most wholesale bullion dealers offer a storage solution
                since the vast majority of their clients need to store the
                quantities they purchased. Such dealers work with top tier
                vaulting companies with whom they get a wholesale deal on the
                storage due to the volumes they bring. Clients are often better
                off going through these intermediaries. Moreover, most
                professional vaulting companies are not too keen in dealing with
                private clients (and some simply refuse to). Lastly, the bullion
                dealer acts as a front for its clients to the vaulting company,
                which adds another layer of confidentiality for the cautious
                investor.
                <br />
                <br />3 – Most of the bullion companies that offer allocated
                pooled accounts (explained above) give the option to pay for the
                fabrication of the bars and have their segregated storage. The
                bar fabrication fees are usually prohibitive.
              </div>
            </div>
          </div>
        </Col>
      </div>,
    ];
  }, []);
  return (
    <div>
      <Row gutter={40}>
        {ws < 576 ? (
          <Col sm={24} className="cr">
            <Row justify="center">
              <Select
                defaultValue={0}
                style={{ width: `100%`, marginRight: 20 }}
                onChange={i => {
                  sa(i);
                }}
                size="large"
              >
                <Select.Option value={0}>HOME STORAGE</Select.Option>
                <Select.Option value={1}>SAFE DEPOSIT</Select.Option>
                <Select.Option value={2}>ACCOUNTS</Select.Option>
                <Select.Option value={3}>POOLED</Select.Option>
                <Select.Option value={4}>SEGREGATED</Select.Option>
              </Select>
            </Row>
          </Col>
        ) : (
          <Col sm={24} md={{ span: 14, offset: 10 }}>
            <div className="cr broshureFiveSteps">
              <Radio.Group
                value={a}
                onChange={e => {
                  sa(parseInt(e.target.value, 10));
                }}
                defaultValue="1"
                size="large"
              >
                <Radio.Button value={0}>HOME STORAGE</Radio.Button>
                <Radio.Button value={1}>SAFE DEPOSIT</Radio.Button>
                <Radio.Button value={2}>ACCOUNTS</Radio.Button>
                <Radio.Button value={3}>POOLED</Radio.Button>
                <Radio.Button value={4}>SEGREGATED</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        )}
      </Row>
      <br />
      <br />
      <Row gutter={40}>{tabs[a]}</Row>
    </div>
  );
};

export const Physical = () => (
  <section className="broshureSec_0 brochurePhysical">
    <TextSection title="STORAGE OPTIONS FOR PHYSICAL BULLION">
      <p className="text-section__info">
        Now that our Wise Investor has decided on the type of bars that he wants
        to purchase, he needs to find out how and where to store them. There are
        many options and one easily can be overwhelmed by the various ways to
        invest in gold. This guide attempts to review the various options that
        exist (even the ones not involving ownership of any bullion, in the next
        section) and make the case for the only way to hold bullion for wealth
        protection that is to have the legal title to bullion bars or coins
        either in your possession or with a trusted supplier.
      </p>
    </TextSection>

    <SubSlides />
  </section>
);
