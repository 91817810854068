import React, { useState, useMemo } from "react";
import { Row, Col, Radio, Select, Tabs } from "antd";
import { TextSection } from "../common/TextSection";

import { useWindowSize } from "../../utils/hooks";
import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

export const Solutions = () => {
  const [a, sa] = useState(0);
  const ws = useWindowSize();
  const { TabPane } = Tabs;
  const tabs = useMemo(() => {
    return [
      <div key="1" className="brochureSolution_slide">
        <div className="brochureSolution_slide_title">
          UNALLOCATED METAL ACCOUNTS
        </div>
        <br />
        <div className="brochureSolution_slide_content">
          Most of the world’s gold is held in unallocated accounts provided by
          financial institutions and large bullion dealers. While those accounts
          are described as the most convenient, cheapest, and most common ways
          to gain exposure to bullion, specific bars are not set aside for the
          client and the account is backed by the credit worthiness of the
          institution.
          <br />
          <br />
          “Unallocated” storage means you own part of a pool of gold with no
          title to any particular bar. Precious metals held in this form do not
          entitle the holder to specific bars and the holder only ranks as an
          unsecured creditor of the dealer or bank in the event of an insolvency
          or bankruptcy. This bullion can be considered part of the general pool
          of metal held by the dealer or bank, and may be lent out or sold
          without the owner’s knowledge or consent. As a result, storage fees
          are much lower than for full custodial storage and are not paid until
          the account is closed or the bullion delivered.
          <br />
          <br />
          <strong>
            The London Bullion Market Association clearly mentions that with
            such account, “The client is an unsecured creditor.”
          </strong>
        </div>
      </div>,

      <div key="2" className="brochureSolution_slide">
        <div className="brochureSolution_slide_title">GOLD FUNDS </div>
        <br />
        <div className="brochureSolution_slide_content">
          Several vehicles allow investors to own bullion indirectly. The
          investor owns shares in a vehicle that is the direct owner of bullion.
          The most popular such vehicles are exchange traded funds (ETF) that
          hold assets and issue shares that are traded on the stock exchange.
          Large broker dealers (the Authorized Participants) act as market
          makers and make sure the shares trade at the net asset value. Other
          vehicles are mutual fund structures with a distinction as to who
          provides the custody of the metal: a bank or a non-bank custodian.
          <br />
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="THE ETFS" key="1">
            <div className="brochureSolution_slide_title">THE ETFS </div>
            <br />
            <div className="brochureSolution_slide_content">
              Gold ETFs offer a convenient way to add tactical gold exposure to
              a portfolio. With an annual fee ostensibly ranging from 0.25% to
              0.4% for most ETFs, this convenience comes with a price given the
              many drawbacks of these products.
              <br />
              <br />
              For the purpose of this guide, I will examine the workings of the
              largest gold ETF (which will remain nameless in this report) which
              currently holds over US$30 billion worth of gold bullion. In such
              structure, investors hold units issued by the trust, with each
              unit representing an undivided interest in the assets of the
              trust.
              <br />
              <br />
              What makes an ETF different from another type of fund is that
              investors cannot interact with the trust directly for the purpose
              of creating or redeeming shares. This process is intermediated by
              Authorised Participants, who are for the most part large banks and
              only they can instruct the trust to create or redeem shares in
              baskets of 100,000 shares (representing about 10,000 oz of gold,
              worth US$13 million). There is no legal obligation for the Trust
              or the Authorized Participants to deliver 10,000 oz of physical
              gold upon presentation of 100,000 shares by an investor (only the
              Authorised Participants may). Most ETFs are therefore paper gold
              instruments for investors because the physical redemption feature
              is only available to Authorized Participants.
              <StaticImage
                src="../../images/brochureSolutionsGoldFundsEtfs.png"
                style={{
                  marginTop: "39px",
                  marginBottom: "50px",
                }}
                alt="ETF map"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
              Furthermore, the gold bullion is in an allocated account held with
              a bullion bank in London for the account of the trust. While this
              seems fine, the prospectus authorises the custodian to use
              undisclosed sub-custodians holding the bullion in undisclosed
              locations. The prospectus also places several limitations to the
              liability of the custodian and sub-custodians, to the ability of
              the trustee to audit the holdings of the trust and the quality of
              the bullion held.
              <br />
              <br />
              Conflicts of interests also have been pointed out between the
              custodians of such ETFs and their investors. Custodians of the two
              major gold and silver exchange traded funds, HSBC and JPMorgan
              Chase, hold great quantities of bullion on behalf of unit holders
              while at the same time holding significant over-the-counter
              derivatives in both gold and silver and significant short
              positions in both gold and silver on the COMEX.
              <br />
              <br />
              Many investors are lured by the seemingly low cost of holding an
              ETF, but they should realize that a management fee of 0.4% is not
              justified for such a large institutional structure. It is also
              obvious that the bulk of the management fee (0.3%) is not used to
              run the product, but to pay the fees of the sponsor and the
              marketing agent.
              <br />
              <br />
              Investors also should factor in the brokerage fee they need to pay
              to their stock broker to acquire the units, and these can be
              substantial, depending on the provider used. While the brokerage
              fee may seem to have nothing to do with the ETF, it adds to the
              total cost to get in or out of the investment and must be taken
              into consideration when making comparisons to the other options
              available (such as the purchase of physical bullion).
              <br />
              <br />
              Most metal ETFs can be regarded as products created by the banks
              and for the profit of the banks. Their workings are intricately
              intertwined with the London Bullion Market and should be avoided
              by the wise long term precious metals investor (please read the
              Appendix for a more detailed analysis on the London Bullion
              Market).
            </div>
          </TabPane>
          <TabPane tab="THE ETNS" key="2">
            <div className="brochureSolution_slide_title">THE ETNS </div>
            <br />
            <div className="brochureSolution_slide_content">
              An exchange traded note (ETN) works in a similar way to an ETF
              with the difference that the investor does not own a share in the
              trust, but owns title to a debt which is secured by bullion owned
              by the trust. The investor is therefore a creditor and is one more
              step away from the ownership of bullion. In fact, he does not own
              bullion at all, not even indirectly.
            </div>
          </TabPane>
          <TabPane tab="BANKS’ SPONSORED FUNDS" key="3">
            <div className="brochureSolution_slide_title">THE ETNS </div>
            <br />
            <div className="brochureSolution_slide_content">
              Many banks have sponsored their own fund and act as fund manager
              and gold custodian. Many such funds have been established by Swiss
              banks which hold the gold in their own vault, often located in the
              basement of their main building. Such investment funds are clearly
              superior to ETFs but present some evident drawbacks: <br />
              <br />
              1. Investors can face high fees to subscribe or redeem units of
              the fund, depending on the particular distribution channel into
              which they fall. This is not applicable to the funds that are
              listed, but brokerage fees and larger spreads for relatively low
              liquidity stocks might prove very expensive.
              <br />
              <br />
              2. Those funds are holding large bank bars of 400 oz, which are
              worth more than US$500,000. Physical redemption is possible, but
              only for such bars and therefore is only available to large
              investors.
              <br />
              <br />
              3. The bank acts as the provider of many services needed by the
              fund, including custody of the gold. This might create conflicts
              of interest in situations of distress for the institution in
              question.
              <br />
              <br />
              4. Rules and regulations are changing fast in European countries
              and the conditions for the redemption of units for physical metal
              might change in the future and be out of the control of the
              provider.
            </div>
          </TabPane>
          <TabPane tab="FUNDS WITH NON-BANK CUSTODY " key="4">
            <div className="brochureSolution_slide_title">
              FUNDS WITH NON-BANK CUSTODY
            </div>
            <br />
            <div className="brochureSolution_slide_content">
              Funds such as the Sprott Physical Trust or the Central Fund of
              Canada have long earned the best-in-class reputation of all
              precious metals funds. They are closed-ended funds that acquire
              metal after each round of share offering and store the metal with
              non-bank custodians. Their shares can easily be acquired on stock
              exchanges in Canada and the USA. While superior to the bank
              sponsored funds, they suffer from a few drawbacks as well: <br />
              <br />
              1. Acquiring the shares can be pricey, depending on the investor’s
              brokerage arrangement and the liquidity and spread of the stock.
              <br />
              <br />
              2. The funds are backed by large bars which then constitute the
              minimum holding required to request physical delivery. <br />
              <br />
              3. Being closed-ended funds, their shares can trade sometimes at a
              significant premium or discount to the Net Asset Value, adding
              another layer of volatility to an already volatile asset.

              <StaticImage
                src="../../images/fundsWithNonBank.png"
                style={{
                  marginTop: "39px",
                }}
                alt="Stack of Gold bars"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>,
      <div key="3" className="brochureSolution_slide">
        <div className="brochureSolution_slide_title">
          FUTURES AND OPTIONS TRADING{" "}
        </div>
        <br />
        <div className="brochureSolution_slide_content">
          Futures contracts constitute an efficient way to purchase and take
          delivery of metal in lots of 100 ounces for gold and 5,000 ounces for
          silver. It is one of the cheapest ways to acquire and take delivery of
          metal for investors who have access to a broker that allows for
          physical delivery (most brokers do not).
          <br />
          <br />
          Buying futures contracts and rolling them over does not, however,
          constitute a viable alternative to holding bullion. The investor who
          acquires his bullion in this way also should have sufficient capital
          on margin, given the high volatility of precious metals prices. The
          Wise Investor can acquire his metal on the futures markets and get it
          delivered to his allocated account held with a non-bank professional
          vaulting company. <br />
          <br />
          The main commodity exchanges for gold and silver are COMEX in New
          York, TOCOM in Tokyo, and MCX in India. Gold also can be traded on
          other commodity exchanges, including the Chicago Board of Trade,
          Istanbul Gold Exchange, Chinese Gold and Silver Exchange Society, the
          Shanghai Gold Exchange, and Dubai Commodity Exchange. Only a small
          percentage of the futures market turnover ever comes to physical
          delivery of the gold or silver represented by the contracts traded.
        </div>
      </div>,
      <div key="4" className="brochureSolution_slide">
        <div className="brochureSolution_slide_title">SUMMARY </div>
        <br />
        <div className="brochureSolution_slide_content">
          The chart below summarizes the various ways of holding bullion. Please
          note that taking possession of metal located in an unallocated or pool
          account is possible, but only after switching to an allocated
          segregated account.
        </div>

        <StaticImage
          src="../../images/solutionsSummary.png"
          style={{
            marginTop: "39px",
          }}
          alt="Holding bullion chart"
          placeholder="blurred"
          layout="fullWidth"
          formats={["auto", "webp"]}
        />
      </div>,
    ];
  }, []);
  return (
    <div>
      <section>
        <TextSection title="OTHER GOLD INVESTMENT SOLUTIONS">
          <div className=" broshureFiveSteps">
            {ws < 576 ? (
              <Row justify="center">
                <Select
                  defaultValue={0}
                  style={{ width: `100%`, marginRight: 20 }}
                  onChange={i => {
                    sa(i);
                  }}
                  size="large"
                >
                  <Select.Option value={0}>Unallocated metal</Select.Option>
                  <Select.Option value={1}>Gold funds</Select.Option>
                  <Select.Option value={2}>Futures & options</Select.Option>
                  <Select.Option value={3}>Summary</Select.Option>
                </Select>
              </Row>
            ) : (
              <Radio.Group
                value={a}
                onChange={e => {
                  sa(parseInt(e.target.value, 10));
                }}
                defaultValue="1"
                size="large"
              >
                <Radio.Button value={0}>Unallocated metal</Radio.Button>
                <Radio.Button value={1}>Gold funds</Radio.Button>
                <Radio.Button value={2}>Futures & options</Radio.Button>
                <Radio.Button value={3}>Summary </Radio.Button>
              </Radio.Group>
            )}
            <div style={{ paddingTop: 50 }}>{tabs[a]}</div>
          </div>
        </TextSection>
        {a === 0 ? (
          <div className="solutions_sec1 broshureSec">
            <Row gutter={40} align="top">
              <Col sm={24} md={10}>
                <div className="side_card-left cl">
                  “PAPER MONEY EVENTUALLY RETURNS TO ITS INTRINSIC VALUE – ZERO”
                </div>
              </Col>
              <Col sm={24} md={14}>
                <div className="cr">
                  The reality behind these accounts is that they are an
                  investment structure whereby the investor provides free
                  capital to the supplier (and sometimes pays a small fee to do
                  so) and risks the loss of his capital should the supplier
                  become insolvent. As an investor in unallocated gold, your
                  metal is on the balance sheet of the supplier as a liability
                  and you remain exposed to its insolvency. The actual metal
                  doesn’t necessarily need to exist, pursuant to the terms and
                  conditions of the unallocated storage contract. These accounts
                  typically charge no storage fee or a small fee and do not
                  allow for physical delivery to clients. Unallocated accounts
                  usually have an option to convert into allocated at the
                  investor’s option and additional cost.
                  <br />
                  <br />
                  With an unallocated account, the client does not own bullion,
                  but is merely an unsecured creditor of the bank. The bank may
                  or may not have physical gold to back its liability to the
                  client. The units of these accounts are typically 1 fine ounce
                  of gold and 1 ounce of silver, based upon a London Good
                  Delivery gold bar of a purity of at least .995 and a .999-fine
                  London Good Delivery silver bar, respectively. There are
                  several types of unallocated accounts offered by different
                  suppliers
                </div>
              </Col>
            </Row>
            <div className="header-home">
              <div className="broshureSec_cardList">
                {[
                  "Bullion banks (such as JP Morgan, HSBC, Bank of Nova Scotia, Barclays, UBS, Deutsche Bank) offer unallocated accounts that are available to investment companies or high-net-worth individuals through their private bank account.",
                  "Large refineries offer unallocated accounts to their clients. Of all unallocated accounts, those offered by reputed refineries are probably the safest option because they have a natural flow of gold to refine. ",
                  "Large bullion dealers offer unallocated accounts that are backed by the rolling inventory of the company. Those accounts work in a similar way as described above and are usually put in place for the dealer to finance its inventory required for its operations. ",
                  "Retail bank metal accounts work similarly to a bank account, with the currency of account the being gold gram. As an indication of the fee involved with retail gold accounts, a Singaporean bank charges 0.25% per annum of the value of the account .",
                  "Retail bank metal accounts work similarly to a bank account, with the currency of account the being gold gram. As an indication of the fee involved with retail gold accounts, a Singaporean bank charges 0.25% per annum of the value of the account .",
                ].map((e, i) => (
                  <div key={`${i}-ksdaf`} className="broshureSec_cardListItem">
                    <div className="broshureSec_cardListItemTitle">{i + 1}</div>
                    <div>{e}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
};
