import React, { useState, useMemo } from "react";
import { Col, Row, Radio, Select } from "antd";
import { TextSection } from "../common/TextSection";

import { useWindowSize } from "../../utils/hooks";
import { StaticImage } from "gatsby-plugin-image";

const Tabtoob = () => {
  const [a, sa] = useState(0);
  const ws = useWindowSize();
  const tabs = useMemo(() => {
    return [
      <div key="1">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>CHOOSE YOUR METAL</h1>
            <div>
              The four precious metals are gold, silver, platinum, and
              palladium. Most people are very familiar with gold, which has
              stood as the monetary metal of choice for millennia. Most people
              overlook gold’s close cousin, silver – which has a long history of
              being used as a monetary metal on top of being a ubiquitous
              industrial metal (used in the electronics, energy, and medical
              industries).
              <br />
              <br />
              Without going into great detail, silver generally follows gold’s
              price movements in a much more volatile way. It has been described
              as gold on steroids and produces magnified gains when the metals
              appreciate (and magnified losses when the metals go down). An
              investor with a solid risk appetite and who thinks that gold will
              appreciate should therefore have an allocation to silver.
              <br />
              <br />
              Silver is roughly 65 times cheaper (at current prices) than gold,
              which makes it far more affordable than gold but also greatly
              increases its transaction costs (since transaction related costs
              are spread on an item of much lower value). Silver’s lower value
              would make it appropriate for transacting everyday items in a
              scenario of economic chaos. An ounce of gold is far too powerful
              for such purpose.
              <br />
              <br />
              Platinum and palladium make up the platinum group metals (PGMs)
              that are rare precious metals with unique physical properties. The
              largest use for both metals is in the production of catalytic
              converters used in the automotive industry to reduce harmful
              emissions. The second largest use for both metals is the jewelry
              industry. In addition to catalytic converters and jewelry, both
              metals are used in diverse industrial applications in the
              electronics, medical and petroleum industries.
              <br />
              <br />
              The use of platinum and palladium for coinage and monetary
              purposes is generally limited. The demand for both metals is
              therefore much linked to the continued expansion of the automotive
              industry and the regulatory push for more stringent vehicle
              emission standards. The investment case for PGMs lies more with
              the limited global supply of both metals: more than 80% of world
              palladium production is concentrated in just two countries –
              Russia and South Africa. Russia alone accounts for nearly half of
              total palladium supply. Continued growth of the automotive
              industry coupled with supply disruptions could therefore
              constitute a strong investment case for both metals.
            </div>
          </div>
        </Col>
      </div>,

      <div key="2">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>KNOW THE SPOT PRICE OF YOUR METAL</h1>
            <div>
              The price of metals quoted by dealers always involves two
              components: the international spot price and the purchase premium.
              Because the spot price makes up the bulk of the purchase price and
              is easily found, the Wise Investor should always be aware of the
              spot price prior to making a purchase. He should also be able
              easily to convert the spot price per ounce into the weight of the
              bar contemplated.
              <br />
              <br />
              The spot price refers to the price that is determined on key
              markets where metal is traded. The spot price is quoted around the
              clock and refers to the price determined in the London Bullion
              Market during European trading hours and on the futures markets,
              thereafter, in New York, Sydney, and Hong Kong. This price
              fluctuates every moment and determines the bulk of the purchase
              price of the metal.
            </div>
            <StaticImage
              src="../../images/graph-1.png"
              imgStyle={{
                mixBlendMode: "darken", margin: "50px 0",
                width: "100%",
                height: "auto"
              }}
              alt="Graph"
              placeholder="blurred"
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
            <div>
              The chart below shows the spot price for gold on May 1st 2014.
              Intraday price swings of one percent are common in the gold
              market. This reinforces the need for the Wise Investor to know the
              latest spot price when making a purchase.
              <br />
              <br />
              Knowing the spot price, the investor can determine the quantity of
              bullion that he can afford with his budget. For example, an
              investor willing to invest US$200,000 in gold bullion can easily
              determine that he can afford about 153 ounces of gold (at a spot
              price of US$1,300 per ounce).
            </div>
          </div>
        </Col>
      </div>,

      <div key="3" className="broshureSTab">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr ">
            <div>
              <h1>KNOW THE SPOT PRICE OF YOUR METAL</h1>
              <div>
                <h3>ITS FORM</h3>
                <Row>
                  <Col xs={20}>
                    <div className="broshureSTab_par">
                      Physical metal comes in the form of bullion (bars
                      purposely made for investment purposes) or coins which are
                      minted by national mints and refiners worldwide. A
                      distinction can be made between bullion coins and
                      numismatic coins, which are collectible items commanding a
                      higher price and which should only be considered by
                      connoisseurs.
                    </div>
                  </Col>
                  <Col xs={4}>
                    <StaticImage
                      src="../../images/img12.png"
                      imgStyle={{ mixBlendMode: "darken" }}
                      alt="Suisse 100g Gold Bar"
                      placeholder="blurred"
                      layout="fullWidth"
                      formats={["auto", "webp"]}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <h3>ITS WEIGHT</h3>
              <div className="broshureSTab_par">
                Bullion comes in bars of different weights, with the standard
                unit being the troy ounce (31.1 grams) for precious metals.
                Bullion bars are available in weight denominations ranging from
                1 gram to 400 ounces (12.4 kilos) for gold and 1,000 ounces (31
                kilos) for silver. The investor has a wide range of weights and
                sizes from which to choose, which can be confusing at first.
                <br />
                <br />
                Most people are not accustomed to dealing with troy ounces as a
                unit of weight. A troy ounce is approximately 31.1 grams, which
                renders conversion into grams and kilos rather difficult. As a
                simple rule, there are 32.151 gross ounces in a kilo. Keeping
                that number in mind allows us easily to calculate the number of
                ounces contained in bars which are denominated in grams.
                <br />
                <br />
                For example, with a spot price of $1,300, the spot value of a
                gold bar weighing 100 grams with a purity of 999.9 is US$4,179
                (1300 x 3.2151 x 99.99%).
              </div>
            </div>
            <div>
              <h3>ITS FINENESS</h3>
              <div className="broshureSTab_par">
                All bullion bars and coins have a specific fineness, which is
                the ratio of the precious metal it contains to any impurities.
                It is traditionally expressed as parts per 1,000 (or on a scale
                of 24 karats for jewelry). Most bullion products have a fineness
                of at least 999.5 (which equates to 99.95% pure metal) for gold
                and 999 (99.9%) for silver.
              </div>
            </div>
          </div>
        </Col>

        <Row gutter={40} style={{ paddingTop: 10 }}>
          <Col xs={24} md={10}>
            <div className="side_card-left cl">
              IT IS ESSENTIAL TO PURCHASE METAL THAT IS MANUFACTURED BY A
              REFINER THAT IS ON THE GOOD DELIVERY LIST.
            </div>
          </Col>
          <Col xs={24} md={14}>
            <div className="cr broshureSTab_i55_txt">
              <h3>ITS BRAND</h3>
              <div>
                Mined gold is brought in rough and impure form to refiners that
                mold it into standard bars recognized by the international
                market. The brand of a bar is the refiner and it is always
                marked on the bar. The quality of a bar is therefore dependent
                on the reputation of its refiner. It would be difficult for
                individual investors to track the reputation of each refiner
                they encounter prior to making a purchase.
                <br />
                <br />
                Fortunately, a trade association called the London Bullion
                Market Association (LBMA) has defined the standards that gold
                and silver products must meet to be traded on exchanges. The
                LBMA has set up an accreditation process for refiners that meet
                their stringent quality criteria, as well as regular production
                checks. Such LBMA accredited status is being held by about 70
                refiners worldwide for gold and 80 refiners for silver. The list
                of accredited refiners is called the Good Delivery List and is
                available on the LBMA website (http://www.lbma.org.uk). The
                London Platinum and Palladium Market association performs a
                similar role for platinum and palladium.
                <br />
                <br />
                It is essential to purchase metal that is manufactured by a
                refiner that is on the Good Delivery List because the quality of
                the bar (the accuracy of the weight and fineness indicated on
                the bar) is widely recognized. Bars fabricated by little-known
                refineries might be hard to resell, and the buyer must also
                contend with counterfeit risk. There are some very reliable
                refiners that are not on that Good Delivery List but it
                generally advisable to hold the most widely recognized form of
                bullion.
              </div>
            </div>
          </Col>
        </Row>
      </div>,

      <div key="4">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr">
            <h1>KNOW THE PURCHASE PREMIUM</h1>
            <div>
              The spot price applies to metal that may or may not exist in
              physical form at the moment of trading and that is not readily
              available for the purchaser in a particular place. An extra layer
              of cost needs to be added to that spot price for an actual piece
              of bullion to be produced and shipped to the right location, and
              that is the purchase premium.
              <br />
              <br />
              The purchase premium is typically between decimals of a percentage
              point to a few percentage points of the spot price for gold (it is
              a lot higher for silver, which has a much lower value per weight).
              The premium covers all the costs related to the physical aspect of
              the bullion, such as the manufacturing cost of a bar, its insured
              transportation to the desired location, its insured storage along
              the way, and the margin of the bullion dealer selling it. The
              purchase premium includes the compensation of all the parties
              involved in making the bar and transporting it securely to the
              buyer.
              <br />
              <br />
              The first question that should be asked when considering the
              purchase of a particular bar is, “What is the premium?” The spot
              price is transparent and the buyer should be aware of it at the
              time of purchase. The dealer has no way to impact or cheat on that
              component of the price. He only has control on the premium and
              should be transparent about it. It sometimes takes a lot of effort
              for a customer in a physical or online shop to figure out the
              magnitude of that premium. A dealer should be transparent about
              the premium he charges and should clearly indicate it in
              percentage points for each bullion product. Moreover, he should
              always quote the price his metal in U.S. dollars, with a local
              currency equivalent if the settlement currency is not the U.S.
              dollar.
              <br />
              <br />
              The premium includes the manufacturing cost of the bar, which is
              more or less similar irrespective of the size of the bar. The
              refiner needs to perform a similar amount of work to pour the gold
              needed to make a 100-gram cast bar, a 400-ounce bar, or a 1-kilo
              silver cast bar. As a result, the premium in percentage points is
              the highest for smaller value products. The chart below provides
              an indication of the premium for the four metals and for the most
              common sizes of bullion.
            </div>
            <StaticImage
              src="../../images/graph-2.png"
              imgStyle={{
                mixBlendMode: "darken", margin: "50px 0",
                width: "100%",
                height: "auto"
              }}
              alt="Typical premium per metal and bullion size Graph"
              placeholder="blurred"
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
            <div>
              Some dealers (not all) buy back metal from customers.
              Independently from the requirement to test the bars at the cost of
              the seller, the dealer usually will buy back bullion products at a
              discount off the spot price. This is so that he can make a spread
              on the subsequent sale of the bar to another customer or, should
              he be unable to find another customer, to cover the cost to ship
              the bar and sell it to another dealer or refiner (who buys back at
              a discount).
              <br />
              <br />A note should be made here that gold, as a rule, is never
              available at a discount off its spot price. Gold is an extremely
              liquid asset recognized globally. Gold at a discount would be akin
              to being offered US Dollars at a discount, which would seem
              suspicious for anyone. In most cases, such schemes to buy gold at
              a discount are scams run by con artists who try to lure and
              confuse investors with complex gold acquisition procedures that
              always involve a down payment to secure the sought after bullion.
              The exceptions to the rule and the genuine cases where gold is
              available at a discount are: the clueless seller who doesn’t know
              how gold is priced or a seller trying to get rid of gold acquired
              illegally (from theft, illegal mining or from politically exposed
              persons).
            </div>
          </div>
        </Col>
      </div>,

      <div key="5" className="broshureSTab">
        <Col xs={24} md={{ span: 14, offset: 10 }}>
          <div className="cr ">
            <div>
              <h1>DECIDE ON THE FINAL ALLOCATION TO SPECIFIC PRODUCTS</h1>
              <div>
                The new investor should remind himself that precious metals are
                commodities and that bullion derives its value solely from its
                fine metal content. A Wise Investor will try to get the maximum
                amount of fine metal at the cheapest price. He should look for
                the product that meets his budget and has the lowest premium. He
                should also take into consideration his need for future
                liquidity on his investment (selling part of his holdings to pay
                for living expenses) and his expectation to need his bullion to
                transact in case of severe economic distress (to buy food with
                metal for example).
                <br />
                <br />
                Let’s take a concrete example. An individual has decided to
                invest US$200,000 in physical bullion. He can follow this simple
                three-step approach to determine what products to consider:
                <br />
                <br />
                The item with the lowest premium is the kilo bar. Slightly
                exceeding his budget, he can buy 5 kilo bars. He expects that he
                might need to free up US$60,000 over the next few years to pay
                for living expenses (should his current income decrease). He
                wishes to have that liquidity in smaller tranches of US$5,000.
                He therefore allocates the budget of 2 kilo bars to smaller bars
                of 100 grams worth approximately US$4,200 each. He allocates to
                his portfolio 14 gold bars of 100 grams, which have a value of
                US$58,800. He fears that he might someday need to transact with
                his bullion in a situation of economic disorder. Given the high
                value of gold, he decides to allocate US$20,000 to buy silver
                bullion. He purchases 1,000 silver bars of 1 ounce (each worth
                about US$20). He forgoes 5 bars of 100 grams to purchase his
                silver allocation.
              </div>
            </div>
            <div style={{ color: "#020202", marginTop: 20, marginBottom: 20 }}>
              At a spot price of $1,300 per ounce for gold and $20 for silver,
              the investor will have the following allocation:
              <br />
              <br />3 kilo gold bars with a premium of 1% for a total cost of
              $125,377. 9 gold bars of 100 grams with a premium of 2.5% for a
              cost of $38,553. 1,000 ounces of 1 ounce silver ingots with a
              premium of 17% for a cost of $23,400. The total cost of the
              investment is $187,330 and the investor can allocate the balance
              amount to the product of his choice.
            </div>
          </div>
        </Col>

        <Row gutter={40}>
          <Col xs={24} md={10}>
            <div className="side_card-left cl">
              A WISE INVESTOR WILL TRY TO GET THE MAXIMUM AMOUNT OF FINE METAL
              AT THE CHEAPEST PRICE.
            </div>
          </Col>
          <Col xs={24} md={14}>
            <div className="cr">
              <StaticImage
                src="../../images/graph-3.png"
                imgStyle={{
                  mixBlendMode: "darken", margin: "0 0 50px 0",
                  width: "100%",
                  height: "auto"
                }}
                alt="Precious Metals Allocation Graph"
                placeholder="blurred"
                layout="fullWidth"
                formats={["auto", "webp"]}
              />
            </div>
          </Col>
        </Row>
      </div>,
    ];
  }, []);
  return (
    <div>
      <Row gutter={40}>
        {ws < 576 ? (
          <Col sm={24} className="cr">
            <Row justify="center">
              <Select
                defaultValue={0}
                style={{ width: `100%`, marginRight: 20 }}
                onChange={i => {
                  sa(i);
                }}
                size="large"
              >
                <Select.Option value={0}>Step 1</Select.Option>
                <Select.Option value={1}>Step 2</Select.Option>
                <Select.Option value={2}>Step 3</Select.Option>
                <Select.Option value={3}>Step 4</Select.Option>
                <Select.Option value={4}>Step 5</Select.Option>
              </Select>
            </Row>
          </Col>
        ) : (
          <Col sm={24} md={{ span: 14, offset: 10 }}>
            <div className="cr broshureFiveSteps">
              <Radio.Group
                value={a}
                onChange={e => {
                  sa(parseInt(e.target.value, 10));
                }}
                defaultValue="1"
                size="large"
              >
                <Radio.Button value={0}>Step 1</Radio.Button>
                <Radio.Button value={1}>Step 2</Radio.Button>
                <Radio.Button value={2}>Step 3</Radio.Button>
                <Radio.Button value={3}>Step 4</Radio.Button>
                <Radio.Button value={4}>Step 5</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        )}
      </Row>
      <br />
      <br />
      <Row gutter={40} style={{ paddingBottom: 100 }}>
        {tabs[a]}
      </Row>
    </div>
  );
};

export const FiveStep = () => (
  <section className="broshureSec_0">
    <TextSection title="THE FIVE-STEP APPROACH TO PURCHASING PHYSICAL BULLION">
      <p className="text-section__info">
        The Wise Investor understands that the direct ownership of unencumbered
        physical bullion is the best and only way to invest in metals. Once he
        has made up his mind to buy precious metals, he faces the challenge of
        choosing the specific bars that he should buy.
        <br />
        <br />
        Some bullion dealers carry tens of products across the four precious
        metals and it can be daunting to examine them all. This section outlines
        a five-step approach that the Wise Investor can follow to acquire
        physical bullion.
      </p>
    </TextSection>
    <Tabtoob />
  </section>
);
