import React from "react";

function RightArrow({ color = "#020202" }: any) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7L3.83 7L7.41 10.59L6 12L5.24537e-07 6L6 5.24537e-07L7.41 1.41L3.83 5L18 5L18 7Z"
        fill={color}
      />
    </svg>
  );
}

export default RightArrow;
