import React from "react";
import { Row, Col } from "antd";
import { TextSection } from "../common/TextSection";

export const Disclaimer = () => (
  <section className="broshureSec_0" style={{ paddingBottom: 100 }}>
    <TextSection title="Disclaimer">
      <p className="text-section__info disclaimer__bottom">
        This report and the contents thereof are of a general nature only and
        neither represents nor is intended to be specific advice on any
        particular matter. You must not rely on information provided on this
        report for investment decisions and such information does not constitute
        an offer of or solicitation for the purchase or disposal of, trading or
        any transaction of any asset or security whatsoever. You should assess
        whether the information is appropriate for you and talk with your
        financial or other professional adviser before making an investment or
        financial decision.
        <br />
        <br />
        The contents of this report are not to be relied upon as a substitute
        for financial or other professional advice. Any rates, fees or charges
        quoted are indicative only. You agree that your access to, and use of,
        this information is subject to these terms and all applicable laws, and
        is at your own risk. This report and its contents are provided to you on
        «as is» basis, the report may contain errors, faults and inaccuracies
        and may not be complete and current. The Author or Global Precious
        Metals Pte Ltd make no representations or warranties of any kind, either
        express or implied, including but not limited to warranties of title,
        non-infringement, merchantability or fitness for a particular purpose,
        in relation to the availability, accuracy, completeness, reliability or
        content of this report. <br />
        <br />
        Neither the Author or Global Precious Metals Pte Ltd, nor its
        affiliates, directors, officers, employees, agents, contractors,
        successors or assigns will be liable for any damages whatsoever arising
        out of, or in any way related to, the use of this report and any other
        report or website linked to this report. This limitation applies to
        direct, indirect, consequential, special, punitive or other damages you
        or others may suffer, as well as damages for loss of profits, business
        interruption or the loss of data or information.
        <br />
        <br />
        The information presented herein represents the views of the Author and
        not those of Global Precious Metals Pte Ltd, nor its affiliates,
        directors, officers and employees.
        <br />
        <br />
        Published under the Creative Commons Attribution License 3.0.
        <br />
        <a href="http://creativecommons.org/licenses/by/3.0/">
          {" "}
          http://creativecommons.org/licenses/by/3.0/
        </a>
      </p>
    </TextSection>
    <Row gutter={40}>
      <Col
        xs={24}
        md={{ span: 14, offset: 10 }}
        lg={{ span: 14, offset: 10 }}
        xl={{ span: 14, offset: 10 }}
      >
        <div className="cr side_card-right">
          “THE PROCESS [OF DEBAUCHING THE CURRENCY] ENGAGES ALL THE HIDDEN
          FORCES OF ECONOMIC LAW ON THE SIDE OF DESTRUCTION, AND DOES IT IN A
          MANNER WHICH NOT ONE MAN IN A MILLION IS ABLE TO DIAGNOSE.”
          <div className="disclaimer__line" />
          <div className="disclaimer__auter">JOHN MAYNARD KEYNES </div>
        </div>
      </Col>
    </Row>
  </section>
);
